import React from "react"
import { Link } from "gatsby"

const Style = {
  Button: {
    all: "frk-bold bg-primary text-white text-center active:bg-active",
    small: " py-4 text-sm lg:text-base ",
    large: "",
  },
  ImageComp: {
    all:"w-4 sm:w-5"
  }
}

const ImageComp = ({image}) => {
  if (image && image.length > 0 ) {
    return (
      <img src={require(`../images/${image}`)} alt={`btn_${image}`} className={`${Style.ImageComp.all}`}/>
    )
  }
  else return ""
}

const ButtonHB = ({ type, onClickTypeButton, image, text, to, className }) => {
  if (type.toLowerCase() === "link") {
    return (
      <Link
        className={`${Style.Button.all} ${Style.Button.small} ${Style.Button.large} ${className}`}
        to={to}
      > 
        <ImageComp image = {image} />
        {text}
      </Link>
    )
  }
  else if(type.toLowerCase() === "button") {
    return (
      <button
        className={`${Style.Button.all} ${Style.Button.small} ${Style.Button.large} ${className}`}
        onClick={onClickTypeButton}
      >
        <ImageComp image = {image} />
        {text}
      </button>
    )
  }
}

export default ButtonHB
